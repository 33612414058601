import React, {useEffect} from "react";
import PropTypes from "prop-types";
import '../utils/fontface.css';

import Header from "../components/molecules/Header";
import Transition from "../components/atoms/Transition";

import GlobalStyles from "../components/atoms/GlobalStyles";
import styled, {ThemeProvider} from "styled-components";
import {theme} from "../utils/styling";
import {useWindowSize} from "react-use";

const Main = styled.main`
  //margin: 5rem 0 10rem 0;
`;

function Index({location, children}) {
    const size = useWindowSize();

    useEffect(() => {
        document.documentElement.style.setProperty('--windowHeight', `${size.height}px`);
    }, [size]);

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles/>
            <Header/>
            <Main>{children}</Main>
        </ThemeProvider>
    );
}

Index.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Index;
