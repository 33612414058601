import React from "react"
import {TransitionGroup, CSSTransition} from "react-transition-group"
import styled from "styled-components"

//This variable will be responsible for our animation duration
const timeout = 1500

const fadeOut = timeout / 3;
const fadeIn = timeout / 3;
const scrolling = timeout / 3;

const TransitionHolder = styled.div`
  position: relative;
`

const PageHolder = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;

  &.page-appear,
  &.page-enter {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
  }

  &.page-appear-active,
  &.page-enter-active,
  &.page-appear-done,
  &.page-enter-done {
    opacity: 1;
    transition: opacity ${fadeIn}ms ${fadeOut + scrolling}ms;
  }

  &.page-exit {
    opacity: 1;
  }

  &.page-exit-active {
    opacity: 0;
    transition: opacity ${fadeOut}ms;
  }
`

function Transition({children, location}) {

  return (
    <TransitionHolder>
      <TransitionGroup>
        <CSSTransition
          key={location.pathname}
          timeout={timeout}
          classNames="page"
        >
          <PageHolder>{children}</PageHolder>
        </CSSTransition>
      </TransitionGroup>
    </TransitionHolder>
  )
}

export default Transition
