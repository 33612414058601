import React, {useEffect, useState} from "react";
import styled from "styled-components";
import logo from '../../assets/logo.png'
import EyesSVG from '../../assets/svg/eyes.inline.svg';
import throttle from 'lodash.throttle';

const Holder = styled.header`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;

  a {
    pointer-events: auto;
  }

  .logo, .eyes {
    transform: translateY(0);
    transition: transform 1s ease-in-out;
  }

  &.hide {
    .logo, .eyes {
      transform: translateY(-100px);
      @media ( ${props => props.theme.breakpoints.md} ) {
        transform: none;
      }
    }
  }
`;

const Logo = styled.div`
  position: fixed;
  z-index: 200;
  top: 30px;
  left: 20px;
  //width: 100%;
  @media ( ${props => props.theme.breakpoints.md} ) {
    width: 100%;
    left: 20px;
  }


  a {
    display: block;
    margin: 0;
    @media ( ${props => props.theme.breakpoints.md} ) {
      width: 250px;
    }

    img {
      width: auto;
      height: 24px;
      display: block;
      @media ( ${props => props.theme.breakpoints.md} ) {
        width: 100%;
        height: auto;
      }
    }
  }
`;
const Eyes = styled.div`
  position: fixed;
  z-index: 200;
  top: 30px;
  right: 20px;
  @media ( ${props => props.theme.breakpoints.md} ) {
    bottom: 70px;
    width: 100%;
    left: 20px;
    top: unset;
    right: unset;
  }

  a {
    display: block;
    @media ( ${props => props.theme.breakpoints.md} ) {
      width: 80px;
      margin: 0;
    }

    svg {
      width: auto;
      height: 24px;
      display: block;
      @media ( ${props => props.theme.breakpoints.md} ) {
        width: 100%;
        height: auto;
      }
    }
  }
`;

function Header() {
  const [show, setShow] = useState(true);

  useEffect(() => {

    const onScroll = () => {
      let st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > 100) {
        setShow(false);
      } else {
        setShow(true);
      }
    }

    const throttledCount = throttle(onScroll, 100);
    window.addEventListener('scroll', throttledCount);
    return () => window.removeEventListener('scroll', throttledCount);

  });

  return (
    <Holder className={show ? 'show' : 'hide'}>
      <Logo><a className="logo" href="/"><img src={logo} alt="Studio Libro" /></a></Logo>
      <Eyes><a className="eyes" href="/about"><EyesSVG /></a></Eyes>
    </Holder>
  );
}

export default Header;
