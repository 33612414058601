/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// import smoothscroll from 'smoothscroll-polyfill';
// smoothscroll.polyfill();
//
// export const shouldUpdateScroll = () => {
//   window.setTimeout(() => window.scrollTo({
//     top: 0,
//     left: 0,
//     behavior: 'smooth'
//   }), 500)
//
//   return false
// }

exports.shouldUpdateScroll = () => {

  window.scrollTo(0, 0, 'auto')

  return false
}